import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular-legacy";
import {EventService} from "../../Shared/Services/event.service";
import {Observable} from "rxjs";
import {EventAccountStatus} from "../../../Components/Events/CalendarEventAccount";

@Component({
    selector: 'app-event-account-status-renderer',
    template: `
        <select class="form-control input-sm" style="height: 100%; padding: 0px;" [value]="params.value" (change)="changeHandler($event)">
            <option *ngFor="let status of statuses$ | async" [value]="status.Id" [selected]="params.value === status.Id">{{status.Name}}</option>
        </select>
`,
})
export class EventAccountStatusRenderer implements ICellRendererAngularComp {
    params: any;

    statuses$: Observable<EventAccountStatus[]>;

    constructor(private eventService: EventService) {

    }

    agInit(params: any): void {
        this.statuses$ = this.eventService.getEventStatuses();
        this.params = params;
    }

    changeHandler(event) {
        let value = Number(event.target.value);
        let colId = this.params.column.colId;
        this.params.node.setDataValue(colId, value);
    }

    refresh(params: any): boolean {
        this.params = params;
        return true;
    }
}
