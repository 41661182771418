import {Component, EventEmitter, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {getGridDisplayWords,} from "../../Shared/ag-grid-options";
import {ContactService} from "../../Shared/Services/contact.service";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {BsModalService} from "ngx-bootstrap/modal";
import {combineLatest} from "rxjs";
import {ColDef, RowClassParams, RowClickedEvent} from "ag-grid-community";
import {AttendeeFormComponent} from "./AttendeeForm/attendee-form.component";
import {ActivityService} from "../../Shared/Services/activity.service";
import {EventService} from "../../Shared/Services/event.service";
import {debounceTime, map, startWith, switchMap, tap, withLatestFrom} from "rxjs/operators";
import * as _ from "lodash";
import {Contact} from "../../../Models/Contact";
import { UserService } from "../../Shared/Services/user.service";
import { User } from "../../../Models/User";
import {RoleName} from "../../../Models/Role";

@Component({
    selector: "app-tab-attendee-list",
    template: `
        <div class="flex flex-vbox">
            <div style="margin-bottom: 5px;">
                <form [formGroup]="contactSearchForm">
                    <div class="flex-hbox flex-gap">
                        <div appIfUserFeature="EventAdmin">
                            <button
                                type="button"
                                class="btn btn-sm btn-default"
                                appIfUserFeature="EditContact"
                                appRequiresFeature="contact-add-new"
                                (click)="addAttendee()"
                            >
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>

                        <div
                            class="btn btn-primary btn-sm btn-toggle"
                            btnCheckbox
                            formControlName="myAccounts"
                            tooltip="My Accounts"
                        >
                            <span class="fa fa-sitemap"></span>
                        </div>

                        <div [ngClass]="{'toggleErrors' : anyMismatchedAccounts}" *ngIf="userIsEventAdmin && anyMismatchedAccounts">
                            <div
                                class="btn btn-sm btn-toggle"
                                btnCheckbox
                                formControlName="showMismatchedAccounts"
                                tooltip="Show Errors"
                            >
                                <span class="fa fa-exclamation"></span>
                            </div>
                        </div>

                        <div class="flex-3">
                            <input
                                formControlName="searchTerm"
                                type="search"
                                placeholder="Search"
                                class="flex form-control input-sm"
                            />
                        </div>
                        <app-yes-no-any-picker
                                appIfUserFeature="EventAdmin"
                                formControlName="confirmedStatusFilter"
                                [multiple]="true"
                                [showSelectClearAll]="true"
                                [showTagCountLabel]="true"
                                [closeOnSelect]="false"
                                placeHolder="Confirmed"
                        >
                        </app-yes-no-any-picker>

                        <div class="flex-1">
                            <app-activity-status-picker
                                formControlName="activityStatusFilter"
                                placeHolder="Status"
                                [closeOnSelect]="false"
                                [useNameAsId]="true"
                            >
                            </app-activity-status-picker>
                        </div>
                        <button
                            class="pull-right btn btn-sm btn-default"
                            type="button"
                            tooltip="Download Attendance"
                            (click)="exportAttendees()"
                        >
                            <span class="fa fa-download fa-lg"></span>
                        </button>
                    </div>
                </form>
            </div>

            <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)"
                (rowClicked)="onRowClick($event)"
                [rowClassRules]="rowClassRules"
            >
            </ag-grid-angular>
        </div>
    `,
    styleUrls: ["./tab-attendee-list.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class TabAttendeeListComponent extends BaseGridComponent<AttendeeListRow> implements OnInit {

    @Input()
    eventId: number;

    @Input()
    attendeeDataUpdated = new EventEmitter<boolean>();

    @Input()
    meetingDataUpdated = new EventEmitter<boolean>();

    user: User;

    anyMismatchedAccounts: boolean;

    userIsEventAdmin: boolean;

    contactSearchForm: UntypedFormGroup = this.fb.group({
        searchTerm: this.fb.control(""),
        showMismatchedAccounts: this.fb.control(false),
        myAccounts: this.fb.control(false),
        activityStatusFilter: this.fb.control([]),
        confirmedStatusFilter: this.fb.control([]),
    });

    columnDefs : ColDef[] = [
        {
            field: "IsMultipleAccounts",
            hide: true,
            sort: "desc",
            sortIndex: 1
        },
        {
            colId: "ContactName",
            valueGetter: p => p.data.ContactLastName ? `${p.data.ContactLastName}, ${p.data.ContactFirstName}`: "",
            tooltipValueGetter: p => p.data.ContactLastName ? `${p.data.ContactLastName}, ${p.data.ContactFirstName}`: "",
            headerName: "Contact Name",
            flex: 2,
            minWidth: 147,
            sort: "asc",
            sortIndex: 3
        },
        {
            colId: "PersonalBroker",
            valueGetter: p => p.data.BrokerLastName,
            tooltipValueGetter: p => p.data.BrokerLastName,
            headerName: "Broker",
            flex: 2,
            minWidth: 70
        },
        {
            field: "AccountName",
            tooltipValueGetter: (params) => params.data.AccountName,
            flex: 3,
            minWidth: 100,
            sort: "asc",
            sortIndex: 2
        },
        {
            field: "Tier",
            headerName: "Tier",
            headerTooltip: "Tier",
            width: 50,
        },
        {
            field: "Registered",
            headerName: "Reg",
            headerTooltip: "Registered",
            width: 50,
            cellClass: "highlight"
        },
        {
            field: "Interested",
            headerName: "Int",
            headerTooltip: "Interested",
            width: 40
        },
        {
            field: "Denied",
            headerName: "Den",
            headerTooltip: "Denied",
            width: 50
        },
        {
            field: "Declined",
            headerName: "Decl",
            headerTooltip: "Declined",
            width: 50
        },
        {
            field: "CancelPrior",
            headerName: "CxlPr",
            headerTooltip: "Cancel Prior",
            width: 65
        },
        {
            field: "Attended",
            headerName: "Att",
            headerTooltip: "Attended",
            width: 45,
            cellClass: "highlight"
        },
        {
            field: "Canceled",
            headerName: "Cxl",
            headerTooltip: "Canceled",
            width: 45
        },
        {
            field: "NoShow",
            headerName: "NoSho",
            headerTooltip: "No Show",
            width: 67
        },
        {
            field: "Replay",
            headerName: "Rpl",
            headerTooltip: "Replay",
            width: 43
        },
    ];

    rowClassRules = {
        'duplicate-account-error': (params: RowClassParams<AttendeeListRow>) => {
            return params.data.IsMultipleAccounts;
        },
        'moved-contact': (params: RowClassParams<AttendeeListRow>) => {
            return params.data.ContactHasMoved && !params.data.IsMultipleAccounts;
        },
    };

    constructor(
        private fb: UntypedFormBuilder,
        private modalService: BsModalService,
        private eventService: EventService,
        private activityService: ActivityService,
        private contactService: ContactService,
        private userService: UserService
    ) {
        super();
    }

    ngOnInit(): void {
        this.userService.getCurrentUser().subscribe((user) => {
            this.user = user;
            this.userIsEventAdmin = this.userService.hasUserFeature(user, 'EventAdmin');
            this.contactSearchForm.controls.myAccounts.setValue(this.determineMyAccountsDefault(user.Role.Name, this.userIsEventAdmin));
            this.contactSearchForm.controls.confirmedStatusFilter.setValue([])
        });

        this.gridOptions.overlayNoRowsTemplate = `<span>No Attendees</span>`;
    }

    onGridReady(params) {
        super.onGridReady(params);
        this.setRowData();
    }

    setRowData() {
        this.anyMismatchedAccounts = false;

        let activities$ = this.attendeeDataUpdated.pipe(
            startWith(1),
            tap(() => this.gridApi.showLoadingOverlay()),
            switchMap(() => this.activityService.getActivitiesByEventId(this.eventId)),
            map(activities => activities.filter(a => a.EventMeetingId)));

        let confirmations$ = this.attendeeDataUpdated.pipe(
            startWith(1),
            switchMap(() => this.eventService.getEventContactsByEventId(this.eventId))
        );

        let attendees$ = activities$.pipe(
            switchMap(activities => {
                let contactIds = _.uniq(activities.map(a => a.ContactId));
                return this.contactService.getContactsByIds(contactIds);
            }),
            withLatestFrom(activities$, confirmations$),
            map(([contacts, activities, confirmations]) => {
                let attendees: AttendeeListRow[] = [];

                contacts.forEach((contact) => {
                    let contactActivities = activities.filter(a => a.ContactId === contact.Id);
                    let contactInfo = confirmations.find(a => a.ContactId === contact.Id)
                    let uniqueContactAccounts = [...new Set(contactActivities.map(m => m.ActivityAccountId))];
                    uniqueContactAccounts.forEach(contactAccount => {
                        let contactAccountActivities = contactActivities
                            .filter(ca => ca.ActivityAccountId == contactAccount);
                        attendees.push({
                            ContactId: contact.Id,
                            AccountId: contact.Account?.Id,
                            MyAccount: !!contact.Account?.Tags?.find(t => t === "My"),
                            AccountName: contactAccountActivities[0].AccountName,
                            ContactLastName: contact?.LastName,
                            ContactFirstName: contact?.FirstName,
                            BrokerLastName: contact?.Broker?.LastName,
                            Tier: contact?.Tier,
                            Registered: contactAccountActivities.filter(a => a.Status === "Registered").length,
                            Canceled: contactAccountActivities.filter(a => a.Status === "Canceled").length,
                            NoShow: contactAccountActivities.filter(a => a.Status === "No Show").length,
                            Attended: contactAccountActivities.filter(a => a.Status === "Attended").length,
                            Interested: contactAccountActivities.filter(a => a.Status === "Interested").length,
                            Declined: contactAccountActivities.filter(a => a.Status === "Declined").length,
                            CancelPrior: contactAccountActivities.filter(a => a.Status === "Cancel Prior").length,
                            Replay: contactAccountActivities.filter(a => a.Status === "Replay").length,
                            Denied: contactAccountActivities.filter(a => a.Status === "Denied").length,
                            ActivityStatuses: _.uniq(contactAccountActivities.map(a => a.Status)),
                            IsMultipleAccounts: uniqueContactAccounts.length > 1,
                            ContactHasMoved: contactAccountActivities.filter(m => m.AccountId != m.ActivityAccountId).length > 0,
                            Confirmed: contactInfo?.IsConfirmed ? "Y" : "N",
                        });

                        if(uniqueContactAccounts.length > 1 || contactAccountActivities.filter(m => m.AccountId != m.ActivityAccountId).length > 0) {
                            this.anyMismatchedAccounts = true;
                        }
                    });
                });

                return attendees;
            })
        );

        let formUpdates$ = this.contactSearchForm.valueChanges.pipe(
            startWith(this.contactSearchForm.getRawValue()),
            debounceTime(200),
        );

        this.rowData$ = combineLatest([
            attendees$,
            formUpdates$
        ]).pipe(
            map(([attendees, searchForm]) => {

                return attendees.filter(row =>
                    this.filterAttendeeListRows(
                        row,
                        searchForm.showMismatchedAccounts,
                        searchForm.searchTerm,
                        searchForm.myAccounts,
                        searchForm.activityStatusFilter,
                        searchForm.confirmedStatusFilter,
                    )
                );
            }
        ));
    }

    filterAttendeeListRows(
        row: AttendeeListRow,
        showOnlyMismatchedAccounts: boolean,
        searchTerm: string,
        myAccounts: boolean,
        activityStatuses: string[],
        confirmedStatusFilter: string[],

    ): boolean {
        if (showOnlyMismatchedAccounts && !(row.IsMultipleAccounts || row.ContactHasMoved)) {
            return false;
        }

        if (!myAccounts && (_.isNil(searchTerm) || _.isEmpty(searchTerm)) && activityStatuses.length === 0 && confirmedStatusFilter.length === 0) {
            return true;
        }

        if (myAccounts && !row.MyAccount) {
            return false;
        }

        if (confirmedStatusFilter.length > 0 && !confirmedStatusFilter.includes(row.Confirmed)){
            return false;
        }

        if (activityStatuses.length > 0 && !activityStatuses.some(f => row.ActivityStatuses.includes(f))) {
            return false;
        }

        const search = searchTerm.toLocaleLowerCase();
        if (_.startsWith(row.ContactFirstName.toLocaleLowerCase(), search) || _.startsWith(row.ContactLastName.toLocaleLowerCase(), search)) {
            return true;
        }

        const words = _.words(search, /[^,.\s;]+/g);
        const fieldsContains = _.map([row.ContactFirstName, row.ContactLastName, row.AccountName], f => (f || "").toLowerCase().trim());

        return _.every(words, (w) => {
            return (_.some(fieldsContains, prop => {
                return _.includes(prop, w);
            }))
        });
    }

    filterContacts(contact: Contact, searchTerm: string, myAccounts: boolean): boolean {

        if (!myAccounts && (_.isNil(searchTerm) || _.isEmpty(searchTerm))) {
            return true;
        }

        const myAccountsQuery = (c: Contact): boolean => {
            return !myAccounts || !!c.Account?.Tags?.find(t => t === "My");
        };

        const searchTermQuery = (c: Contact): boolean => {
            const search = searchTerm.toLocaleLowerCase();

            if(_.startsWith(c.FirstName.toLocaleLowerCase(), search) || _.startsWith(c.LastName.toLocaleLowerCase(), search)) {
                return true;
            }

            const words = _.words(search, /[^,.\s;]+/g);
            const fieldsContains = _.map([c.FirstName, c.LastName, c.Account?.Name], f => (f || "").toLowerCase().trim());
            return _.every(words, (w) => {
                return (_.some(fieldsContains, prop => {
                    return _.includes(prop, w);
                }))
            });
        };

        return myAccountsQuery(contact) && searchTermQuery(contact);
    }

    onRowClick($event: RowClickedEvent<any>) {
        this.openAttendeeForm(this.eventId, $event.data.ContactId);
    }

    getDisplayWords(): string {
        return getGridDisplayWords(this.gridApi);
    }

    addAttendee() {
        this.openAttendeeForm(this.eventId, null);
    }

    exportAttendees() {
        this.eventService.getEventAttendeesExport(this.eventId).subscribe();

    }

    openAttendeeForm(eventId: number, contactId: number): void {
        const initialState = {
            contactId: contactId,
            eventId: eventId,
        };
        let modalFormRef = this.modalService.show(AttendeeFormComponent, {
            initialState: initialState,
            animated: false,
            keyboard: false,
            backdrop: "static",
            class: "modal-lg"
        });

        modalFormRef.content.dataUpdated
            .subscribe(_ => {
                this.attendeeDataUpdated.emit(true);
            });
    }
    determineMyAccountsDefault(userRole: string, userIsEventAdmin: boolean):boolean {
        return !userIsEventAdmin && ((userRole === RoleName.Sales) || (userRole === RoleName.Traders));
    }
}

interface AttendeeListRow {
    ContactId: number,
    AccountId: number,
    ContactFirstName: string,
    ContactLastName: string,
    BrokerLastName: string,
    AccountName: string,
    MyAccount: boolean,
    Tier: string,
    Registered: number,
    Canceled: number,
    NoShow: number,
    Attended: number,
    Interested: number,
    Declined: number,
    CancelPrior: number,
    Replay: number,
    Denied: number,
    IsMultipleAccounts: boolean,
    ContactHasMoved: boolean,
    ActivityStatuses: string[]
    Confirmed: string,
}
