import {Component, EventEmitter, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import * as moment from "moment";
import {startWith, switchMap, map, tap} from "rxjs/operators";
import {RVCSummary} from "../../../Models/RVCSummary";
import {combineLatest, forkJoin, iif, Observable, of} from "rxjs";
import {AccountDetailActivityQuery, ActivityService} from "../../Shared/Services/activity.service";
import {AccountService} from "../../Shared/Services/account.service";
import * as _ from "lodash";

@Component({
    selector: "app-rvc",
    templateUrl: "./rvc.component.html",
    styleUrls: ["./rvc.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class RvcComponent implements OnInit {

    @Input()
    accountId: number;

    quarterOptions$: Observable<string[]> = of(["Q1", "Q2", "Q3", "Q4"]);
    displayMeetingSeating$: Observable<string[]> = of(["Seats", "Meetings"]);

    rvcData: RVCSummary[];
    rvcDataLoading: boolean = true;
    relativePayDataLoading: boolean = true;
    relativePayDataLoadedEmitter = new EventEmitter<boolean>();

    currentYear: number = moment().year();
    yearRange = _.range(this.currentYear, this.currentYear - 4);
    accountIds: number[];
    exportDisabled: boolean = false;
    options: UntypedFormGroup = this.fb.group({
        parent: this.fb.control(true),
        conference: this.fb.control(false),
        displayMeetingSeating: this.fb.control("Seats"),
        year: this.fb.control(this.yearRange[0]),
        quarters: this.fb.control([]),
        quart: this.fb.control([]),
        specificMonths: this.fb.control([]),
        sort: this.fb.control("asc"),
        teamIds: this.fb.control([])
    });

    constructor(
        private fb: UntypedFormBuilder,
        private accountService: AccountService,
        private activityService: ActivityService,
    ) {}

    ngOnInit(): void {
        this.relativePayDataLoadedEmitter.subscribe(_ => { this.relativePayDataLoading = false; });

        combineLatest([
            this.options.get("parent").valueChanges.pipe(startWith(this.options.get("parent").value)),
            this.options.get("year").valueChanges.pipe(tap(() => this.relativePayDataLoading = true),startWith(this.options.get("year").value)),
            this.options.get("teamIds").valueChanges.pipe(startWith(this.options.get("teamIds").value)),
            this.options.get("specificMonths").valueChanges.pipe(startWith(this.options.get("specificMonths").value))
        ])
            .pipe(
                switchMap(([parent, year, teamIds, specificMonths]) => {
                    this.rvcDataLoading = true;

                    let accountIds$: Observable<number[]>;
                    if (parent) {
                        accountIds$ = this.accountService.getAccountSubAccounts(this.accountId).pipe(
                            map(accounts => accounts.map(a => a.Id))
                        );
                    } else {
                        accountIds$ = of([this.accountId]);
                    }

                    return forkJoin([
                        accountIds$,
                        of(moment().year(year - 4).startOf("year").toISOString()),
                        of(moment().year(year).endOf("year").toISOString()),
                        of(teamIds),
                        of(specificMonths),
                    ]);

                }),
                tap(([accountIds]) => this.accountIds = accountIds),
                switchMap(([accountIds, startDate, endDate, teamIds, specificMonths]) =>
                    this.activityService.getRvcByAccountIds(accountIds, startDate, endDate, teamIds, specificMonths).pipe(
                        tap(() => {
                            this.rvcDataLoading = false;
                        })
                    )
                ),
            ).subscribe((data) => { this.rvcData = data; });
    }

    export(): void {

        const quarters =  this.options.get("quarters").value;

        const months = this.options.get("specificMonths").value;

        const monthsPartialExportName = (months.length > 0 && months.length < 12)
            ? `Month-${months.join("-")}-`
            : "";

        const quartersPartialExportName = (quarters.length > 0 && quarters.length < 4)
            ? `${quarters.join("-")}-`
            : "";

        const exportName = `AccountRvCActivitiesSummary-${quartersPartialExportName}${monthsPartialExportName}${moment().format("YYYY-MM-DDTHH.mm.ss")}.xlsx`;

        const teamIds = this.options.get("teamIds").value;

        const specificMonths = this.options.get("specificMonths").value;

        this.activityService.getAccountRvCExport(this.accountIds,
            moment().year(this.options.get("year").value - 4).startOf("year").toISOString(),
            moment().year(this.options.get("year").value + 1).startOf("year").toISOString(),
            exportName,
            teamIds,
            specificMonths)
            .subscribe(() => this.exportDisabled = false);
    }

}
