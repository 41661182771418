<form class="form-inline rvc-filters" [formGroup]=options>
    <div class="flex-hbox flex-gap">
        <label><input type="checkbox" formControlName="parent"> Parent</label>
        <label><input type="checkbox" formControlName="conference"> Conferences</label>
        <div class="flex-hbox flex-gap">
            <label>Year</label>
            <select class="form-control input-sm" formControlName="year">
                <option *ngFor="let year of yearRange" [value]="year" [selected]="year === yearRange[0]">{{ year }}</option>
            </select>
            
            <div class="form-group">
                <app-month-picker formControlName="specificMonths"></app-month-picker>
            </div>
            
            <app-string-picker
                    formControlName="displayMeetingSeating"
                    [placeHolder] = "'Seats / Meetings'"
                    name = "Seats / Meetings"
                    [multiple] = false
                    [showSelectClearAll] = false
                    [showTagCountLabel] = false
                    [closeOnSelect] = true
                    [items$]="displayMeetingSeating$"
            >
            </app-string-picker>

            <app-team-picker
                    formControlName="teamIds"
            ></app-team-picker>

            <button (click)="export()" type="button" class="btn btn-sm btn-default" [disabled]="exportDisabled"><span class="fa fa-download fa-lg"></span></button>
        </div>
    </div>
</form>


<div *ngIf="rvcDataLoading || relativePayDataLoading">
    <div class="loading-wrapper">
        <span class="loading-box">Loading...</span>
    </div>
</div>

<div class="flex flex-vbox rvc-grids" [ngStyle]="{ 'visibility': relativePayDataLoading || rvcDataLoading ? 'hidden' : null }">
    <app-relative-pay
            [accountId]="accountId"
            [year]="options.controls['year'].value"
            [dataLoaded]="relativePayDataLoadedEmitter"
            class="combined-grid"
    >
    </app-relative-pay>

    <app-rvc-top
            [rvcData]="rvcData"
            [conferences]="options.controls['conference'].value"
            [year]="options.controls['year'].value"
            [displayMeetingSeating]="options.controls['displayMeetingSeating'].value"
            class="combined-grid"
    >
    </app-rvc-top>

    <app-rvc-bottom
            *ngIf="!options.controls['conference'].value"
            [rvcData]="rvcData"
            [year]="options.controls['year'].value"
            class="combined-grid"
    >
    </app-rvc-bottom>
</div>