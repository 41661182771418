<div class="page-content no-scroll flex-vbox">

  <ng-template #bannerSubTitleTemplate>
        <span>{{ account?.Name2 !== "" ? account?.Name + ' (' + account?.Name2 + ')' : account?.Name }}
          - {{ account?.City }}, {{ account?.State }}
          {{ account?.Country }}</span>
    <span style="padding-left:10px">
            <span *ngIf="isVote" class="label-vote fa fa-check-circle" tooltip="Vote" container="body"
                  [adaptivePosition]="false"></span>
        </span>
    <app-research-access [accountId]="accountId" (click)="focusContactResearch()"></app-research-access>
    <app-related-account-picker [accountId]="accountId" [formControl]="relatedAccount"></app-related-account-picker>
  </ng-template>

  <ng-template #bannerFormTemplate>
    <div class="flex-hbox overflow-ellipsis" style="margin-left: 20px">
      <div class="flex-3 left overflow-ellipsis">
        <div>
          <strong>Tier:</strong>
          {{ account?.Tier }}
        </div>
        <div>
          <strong>Trading Status:</strong>
          {{ account?.Status }}
          <span tooltip="Agreement exists. Contact Trading Operations for current CSA splits."
                container="body"
                *ngIf="account?.IsCSA" style="margin-left: 10px; color: #286090; font-weight: bold;">
                            CSA
                        </span>
        </div>
      </div>
      <div class="flex-3 left overflow-ellipsis">
        <div>
          <strong>PrBk:</strong>
          {{ account?.PrimaryBroker?.LastName }}
        </div>
        <div>
          <strong>PrTr:</strong>
          {{ account?.PrimaryTrader?.LastName }}
        </div>
      </div>
      <div class="flex-3 left overflow-ellipsis">
        <div>
          <strong>ScBk:</strong>
          {{ account?.SecondaryBroker?.LastName }}
        </div>
        <div>
          <strong>ScTr:</strong>
          {{ account?.SecondaryTrader?.LastName }}
        </div>
      </div>
    </div>
  </ng-template>

  <app-page-banner
    faicon="fa-sitemap "
    faiconcolor="#2e8b57"
    [bannerFormTemplate]="bannerFormTemplate"
    [subTitleTemplate]="bannerSubTitleTemplate"
    bannerClass="account-detail-banner">
  </app-page-banner>

  <app-do-not-contact-banner [reason]="account?.ResearchStatus?.Reason"></app-do-not-contact-banner>

  <div class="page-body flex flex-hbox flex-gap">
    <div class="flex flex-vbox flex-gap">
      <div class="flex-3 flex-vbox">
        <tabset #accountDetailTabs class="flex-tabset" style="height: 100%;">
          <tab heading="RvC" *ngIf="isRvCVisible" [active]="defaultTab === 'RvC'">
            <app-rvc [accountId]="accountId" class="flex flex-vbox"></app-rvc>
          </tab>
          <tab heading="Summary" *ngIf="true" [active]="defaultTab === 'Summary'">
            <app-tab-account-summary class="flex flex-vbox"
                                     [accountId]="accountId"
                                     [accountDataChanged]="accountDataChanged"></app-tab-account-summary>
          </tab>
          <tab heading="Contacts" *ngIf="isCommissionsVisible" [active]="defaultTab === 'Contacts'">
            <app-account-contact-list class="flex flex-vbox"
                                      [accountId]="accountId"
                                      [accountDataChanged]="accountDataChanged"></app-account-contact-list>
          </tab>
          <tab heading="Details" *ngIf="true" [active]="defaultTab === 'Details'">
            <app-tab-account-details class="flex flex-vbox"
                                     [accountId]="accountId"
                                     [accountDataChanged]="accountDataChanged"></app-tab-account-details>
          </tab>
          <tab heading="Profile" *ngIf="true" [active]="defaultTab === 'Profile'">
            <app-account-profile class="flex flex-vbox"
                                 [accountId]="accountId"></app-account-profile>
          </tab>
          <tab heading="EES" *ngIf="isEESVisible" [active]="defaultTab === 'EES'">
            <app-account-note class="flex flex-vbox"
                              [accountId]="accountId"
                              [noteType]="'EES'">
            </app-account-note>
          </tab>
          <tab heading="Target" *ngIf="isRevenueTarget" [active]="defaultTab === 'Target'">
            <app-tab-revenue-target-list class="flex flex-vbox"
                                         [accountId]="accountId"
                                         [commissionDataChanged]="commissionDataChanged">
            </app-tab-revenue-target-list>
          </tab>
          <tab heading="Billing" *ngIf="isInvoiceBilling" [active]="defaultTab === 'Billing'">
            <app-billing-info class="flex flex-vbox"
                              [accountId]="accountId">
            </app-billing-info>
          </tab>
        </tabset>
      </div>

      <div class="flex-2 flex-vbox">
        <div style="flex-direction: column; flex: 1; border: 1px solid #DDD;">
          <app-account-contact-list *ngIf="!isCommissionsVisible" class="flex flex-vbox" style="height:100%;"
                                    [accountId]="accountId"
                                    [accountDataChanged]="accountDataChanged"></app-account-contact-list>
          <app-account-commissions *ngIf="isCommissionsVisible" class="flex flex-vbox" style="height:100%;"
                                   [accountId]="accountId"
                                   [commissionDataChanged]="commissionDataChanged"></app-account-commissions>
        </div>
      </div>
    </div>

    <div class="flex flex-vbox">
      <tabset class="flex-tabset">
        <tab heading="Activities">
          <app-tab-activity-list class="flex flex-vbox"
                                 [accountId]="accountId"
                                 [dateRange]="defaultDateRange"
                                 [showAccountName]="false">
          </app-tab-activity-list>
        </tab>
        <tab heading="Interests">
          <app-tab-interest-list class="flex flex-vbox"
                                 [accountId]="accountId">
          </app-tab-interest-list>
        </tab>
        <tab heading="Votes">
          <app-account-votes class="flex flex-vbox" [accountId]="accountId"></app-account-votes>
        </tab>
        <tab heading="McLagan">
          <app-commission-rank class="flex flex-vbox" [accountId]="accountId"></app-commission-rank>
        </tab>
        <tab heading="EES" *ngIf="isEESVisible">
          <app-tab-ees-activity-list class="flex flex-vbox"
                                     [accountId]="accountId"
                                     [showContactShortName]="true"
                                     [showAccountName]="false"
                                     [dateRange]="defaultDateRange"
                                     [dateRangeCenterAlign]="true"></app-tab-ees-activity-list>
        </tab>
      </tabset>
    </div>
  </div>
</div>
