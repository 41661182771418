import { Component, OnInit, HostListener, forwardRef } from "@angular/core";
import { FormBuilder, FormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { of } from "rxjs";

@Component({
    selector: "app-month-picker",
    template: `
        <div class="dropdown" [class.show]="dropdownOpen">
            <button class="dropdown-toggle btn btn-secondary dropdown-button" type="button" (click)="toggleDropdown()">
                {{ placeHolder }} <span class="caret"></span>
            </button>
            <div class="dropdown-menu" [class.show]="dropdownOpen">
                <div class="fixed-section">
                    <button class="btn btn-link btn-sm" type="button" (click)="selectAll($event)">Select All</button>
                    <button class="btn btn-link btn-sm" type="button" (click)="clearAll($event)">Clear All</button>
                </div>
                <div class="scrollable-section">
                    <div *ngFor="let item of items" class="dropdown-item">
                        <div class="form-check" *ngIf="item.Months">
                            <input class="form-check-input" type="checkbox" [id]="item.Name" (change)="onQuarterSelect($event, item)" [checked]="isQuarterSelected(item.Name)">
                            <label class="form-check-label" [for]="item.Name">{{ item.Name }}</label>
                        </div>
                        <ul class="list-unstyled pl-3">
                            <li *ngFor="let month of item.Months" class="form-check">
                                <input class="form-check-input" type="checkbox" [value]="month.Id" [checked]="isMonthSelected(month.Id)" (change)="onMonthSelect($event, month)">
                                <label class="form-check-label">{{ month.Name }}</label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    `,
    styles: [`
        .dropdown-menu {
            display: none;
            font-size: 12px;
            border: 1px solid #cacaca;
            background-color: white;
            max-height: 200px;
            font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
            position: absolute;
            z-index: 1000;
            top: 90%;

        }

        .dropdown-menu.show {
            display: block;
        }

        .fixed-section {
            background-color: white;
            padding: 0.25rem 0.25rem;
            border-bottom: 1px solid #cacaca;
            position: sticky;
            top: 0;
            z-index: 1;
        }

        .scrollable-section {
            max-height: 160px;
            overflow-y: auto;

            .dropdown-item {
                ul {
                    margin-bottom: 0
                }
            }
        }

        .dropdown-item {
            padding: 4px 10px;
        }

        .form-check {
            margin-bottom: 0.25rem;

        }

        .form-check:hover {
            background-color: rgb(245, 245, 245);
        }

        .form-check-input {
            margin-right: 0.75rem;
        }

        .form-check-label {
            font-weight: normal;
        }

        .pl-3 {
            padding-left: 1rem;
        }

        .dropdown-button {
            background-color: white;
            border-color: lightgrey;
            padding: 6px 5px;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .caret {
            margin-left: 0.5rem;
            border-top: 0.4em solid;
            border-right: 0.4em solid transparent;
            border-left: 0.4em solid transparent;
            color: #989898
        }
    `],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MonthPickerComponent),
            multi: true
        }
    ]
})
export class MonthPickerComponent implements OnInit, ControlValueAccessor {
    dropdownOpen = false;
    items: any[] = [];
    pickerControl = new FormControl([]);
    placeHolder = "Month(s)";

    constructor(private fb: FormBuilder) {}

    ngOnInit() {
        of([
            { Id: 1, Name: "Q1", Months: [{ Id: 1, Name: "January" }, { Id: 2, Name: "February" }, { Id: 3, Name: "March" }] },
            { Id: 2, Name: "Q2", Months: [{ Id: 4, Name: "April" }, { Id: 5, Name: "May" }, { Id: 6, Name: "June" }] },
            { Id: 3, Name: "Q3", Months: [{ Id: 7, Name: "July" }, { Id: 8, Name: "August" }, { Id: 9, Name: "September" }] },
            { Id: 4, Name: "Q4", Months: [{ Id: 10, Name: "October" }, { Id: 11, Name: "November" }, { Id: 12, Name: "December" }] },
        ]).subscribe(data => this.items = data);
    }

    toggleDropdown() {
        this.dropdownOpen = !this.dropdownOpen;
    }

    isQuarterSelected(quarterName: string): boolean {
        const quarter = this.items.find(q => q.Name === quarterName);
        return quarter.Months.every(month => this.isMonthSelected(month.Id));
    }

    onQuarterSelect(event: Event, quarter: any) {
        const checkbox = event.target as HTMLInputElement;
        const selectedMonths = this.pickerControl.value;

        if (checkbox.checked) {
            this.pickerControl.setValue([...new Set([...selectedMonths, ...quarter.Months.map(month => month.Id)])]);
        } else {
            this.pickerControl.setValue(selectedMonths.filter(m => !quarter.Months.map(month => month.Id).includes(m)));
        }
        this.updatePlaceholder();
        this.onChange(this.pickerControl.value);
    }

    onMonthSelect(event: Event, month: any) {
        const checkbox = event.target as HTMLInputElement;
        const selectedMonths = this.pickerControl.value;

        if (checkbox.checked) {
            this.pickerControl.setValue([...selectedMonths, month.Id]);
        } else {
            this.pickerControl.setValue(selectedMonths.filter(m => m !== month.Id));
        }
        this.updatePlaceholder();
        this.onChange(this.pickerControl.value);
    }

    isMonthSelected(monthId: number): boolean {
        return this.pickerControl.value.includes(monthId);
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent) {
        if (!(event.target as HTMLElement).closest('.dropdown')) {
            this.dropdownOpen = false;
        }
    }

    writeValue(value: any): void {
        this.pickerControl.setValue(value);
        this.updatePlaceholder();
    }

    onChange: any = () => {};
    onTouched: any = () => {};

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        isDisabled ? this.pickerControl.disable() : this.pickerControl.enable();
    }

    selectAll(event: Event) {
        event.preventDefault();
        const allMonthIds = this.items.reduce((acc, item) => acc.concat(item.Months.map((month: any) => month.Id)), []);
        this.pickerControl.setValue(allMonthIds);
        this.updatePlaceholder();
        this.onChange(this.pickerControl.value);
    }

    clearAll(event: Event) {
        event.preventDefault();
        this.pickerControl.setValue([]);
        this.updatePlaceholder();
        this.onChange(this.pickerControl.value);
    }

    private updatePlaceholder() {
        const selectedCount = this.pickerControl.value.length;
        this.placeHolder = selectedCount > 0 ? `${selectedCount} Month(s)` : "Month(s)";
    }
}
