import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {ColDef, ICellRendererParams} from "ag-grid-community";
import * as _ from "lodash";
import {RVCSummary} from "../../../Models/RVCSummary";

@Component({
    selector: "app-rvc-top",
    templateUrl: "./rvc-top.component.html"
})
export class RvcTopComponent extends BaseGridComponent<RVCSummary> implements OnChanges {

    @Input()
    rvcData: RVCSummary[];

    @Input()
    conferences: boolean;

    @Input()
    displayMeetingSeating: string;

    @Input()
    year: number;

    openGroups: string[] = [];

    meetingColumnDefs: ColDef[] = [
        {field: 'CategoryName', flex: 1, enableRowGroup: true, rowGroup: true, hide: true},
        {field: 'Conference', flex: 1, enableRowGroup: true, rowGroup: false, hide: true},
        {
            field: 'ConferenceSubCategoryGroup',
            flex: 1,
            enableRowGroup: true,
            rowGroup: true,
            hide: true,
            sort: "asc",
        },
        {
            field: 'SubCategory',
            flex: 1,
            enableRowGroup: true,
            rowGroup: true,
            hide: true,
            sort: "asc",
            valueGetter: params => {
                if (params.data?.SubCategory == "Mtg/Cxld/NoShw" || params.data?.SubCategory == "Attd/Cxld/NoShw") {
                    return "Cxld/No Show";
                }
                return params.data?.SubCategory;
            }
        },
        {
            field: 'MeetingCount',
            headerName: 'Mtgs',
            headerTooltip: "Meetings",
            flex: 1,
            sortable: false,
            tooltipValueGetter: params => params.value.Year + ": Mtgs",
            valueGetter: params => {
                return {
                    Year: params.data?.Year,
                    ConferenceSubCategoryGroup: params.data?.ConferenceSubCategoryGroup,
                    IncludedCount: params.data?.MeetingCount ?? 0,
                    toString: () => params.data?.DisplayMeetingCount ?? 0
                }
            },
            valueFormatter: params => {

                if ((params.value[0]?.ConferenceSubCategoryGroup === "Attendance") ) {
                    return "";
                }

                return params.value?.toLocaleString();

            },
            aggFunc: params => this.meetingCountAggFunc(params)
        },
        {
            colId: 'YoYMeetingPercentChange',
            headerName: 'YoY %',
            headerTooltip: "Year over Year Meetings Percent Change",
            flex: 1,
            valueGetter: params => {
                return {
                    Year: params.data?.Year,
                    ConferenceSubCategoryGroup: params.data?.ConferenceSubCategoryGroup,
                    YoYSubCategoryMeetings: params.data?.YoYSubCategoryMeetings,
                    YoYCategoryMeetings: params.data?.YoYCategoryMeetings,
                    YoYSubCategoryGroupMeetings: params.data?.YoYSubCategoryGroupMeetings,
                    CategoryYoYChangeMeeting: params.data?.CategoryYoYChangeMeeting,
                    SubCategoryYoYChangeMeeting: params.data?.SubCategoryYoYChangeMeeting,
                    TotalYoYChangeMeeting: params.data?.TotalYoYChangeMeeting ?? 0,
                    toString: () => params.data?.TotalYoYChangeMeeting ?? 0,
                }
            },
            valueFormatter: params => {

                if (this.conferences) {
                    if (params.value[0]?.ConferenceSubCategoryGroup === "Attendance") {
                        return "";
                    }
                }
                if ((params.value[0]?.ConferenceSubCategoryGroup === "Attendance") && params.node.parent.key == "Conferences") {
                    return "";
                }

                return params.value?.toLocaleString();

            },
            aggFunc: params => this.yoyMeetingPercentChangeAggFunc(params),
            sortable: false,
        },
        {
            field: 'Year',
            headerName: 'Year',
            flex: 1,
            pivot: true,
            hide: true,
            enableRowGroup: true
        }
    ];

    seatsColumnDefs: ColDef[] = [
        {
            field: 'CategoryName',
            flex: 1,
            enableRowGroup: true,
            rowGroup: true,
            hide: true,

        },
        {field: 'Conference', flex: 1, enableRowGroup: true, rowGroup: false, hide: true},
        {
            field: 'ConferenceSubCategoryGroup',
            flex: 1,
            enableRowGroup: true,
            rowGroup: true,
            hide: true,
            sort: "asc",
        },
        {
            field: 'SubCategory',
            flex: 1, enableRowGroup: true,
            rowGroup: true,
            hide: true,
            sort: "asc",
            valueGetter: params => {
                if (params.data?.SubCategory == "Mtg/Cxld/NoShw" || params.data?.SubCategory == "Attd/Cxld/NoShw") {
                    return "Cxld/No Show";
                }
                return params.data?.SubCategory;
            }
        },
        {
            field: 'SeatCount',
            headerName: 'Seats',
            headerTooltip: "Seats",
            flex: 1,
            tooltipValueGetter: params => params.value.Year + ": Seats",
            valueGetter: params => {
                return {
                    Year: params.data?.Year,
                    ConferenceSubCategoryGroup: params.data?.ConferenceSubCategoryGroup,
                    IncludedCount: params.data?.SeatCount ?? 0,
                    toString: () => params.data?.DisplaySeatCount ?? 0
                }
            },
            aggFunc: params => this.seatCountAggFunc(params),
            sortable: false
        },
        {
            colId: 'YoYSeatsPercentChange',
            headerName: 'YoY %',
            headerTooltip: "Year Over Year Seats Percent Change",
            flex: 1,
            valueGetter: params => {
                return {
                    Year: params.data?.Year,
                    ConferenceSubCategoryGroup: params.data?.ConferenceSubCategoryGroup,
                    YoYSubCategorySeats: params.data?.YoYSubCategorySeats,
                    YoYCategorySeats: params.data?.YoYCategorySeats,
                    YoYTotalSeats: params.data?.YoYTotalSeats,
                    YoYSubCategoryGroupSeats: params.data?.YoYSubCategoryGroupSeats,
                    YoYAttendedTotalSeats: params.data?.YoYAttendedTotalSeats,
                    toString: () => 0,
                }
            },
            aggFunc: params => this.yoySeatsPercentChangeAggFunc(params),
            sortable: false,
        },
        {
            field: 'Year',
            headerName: 'Year',
            width: 60,
            pivot: true,
            hide: true,
            enableRowGroup: true
        }
    ];

    columnDefs: ColDef[] = [];

    autoGroupColumnDef: ColDef = {
        headerName: 'Category',
        headerTooltip: "Cancel/No Show is included in totals",
        hide: true,
        sortable: false,
        cellRendererParams: {suppressCount: true},
        sort: "asc",
        width: 133,
        tooltipValueGetter: params => {
            if (params.value === "NDRs") {
                return "NDR";
            }
            if (params.value === "Anly Visits") {
                return "Analyst Visits"
            }
            if (params.value === "Conf Calls") {
                return "Conference Calls";
            }
            if (params.value === "Fld Trip/CV") {
                return "Field Trips and Company Visits";
            }
            if (params.value === "GCTS") {
                return "Global Cons, Tech and Svcs Conference";
            }
            if (params.value === "Healthcare") {
                return "Healthcare Conference";
            }
            if (params.value === "Industrials") {
                return "Industrial Conference";
            }
            if (params.value === "Sustainability") {
                return "Sustainability Conference";
            }
            if (params.value === "VT&M") {
                return "Vehicle Tech and Mobility Conference";
            }
            if (params.value === "HC ESG") {
                return "Healthcare ESG";
            }
            if (params.value === "Govt Svc Def") {
                return "Govt Svcs and Defense Conference";
            }
            if (params.value === "Pvt Tech Svc") {
                return "Private Tech and Svcs Conference";
            }
            if (params.value === "Other") {
                return "Other Conferences";
            }
            if (params.value === "Total") {
                return "Total Conferences";
            }
            if (params.value === "Mtg/Cxld/NoShw") {
                return "Meeting Cxld/No Show"
            }
            if (params.value === "Attd/Cxld/NoShw") {
                return "Attendance Cxld/No Show"
            }
            return params.value;
        },
        comparator: (valueA, valueB) => {
            const conferencesSort = ["GCTS", "Healthcare", "Industrials", "Sustainability", "VT&M", "HC ESG", "Govt Svc Def", "Pvt Tech Svc", "Other", "Total"];
            const nonConferencesSort = ["Conferences", "NDRs", "Anly Visits", "Conf Calls", "Fld Trip/CV"];
            const sortOrder = [...(this.conferences ? conferencesSort : nonConferencesSort), "Meetings", "1x1", "2x1", "3x1", "4x1", "Group", "Dinners", "Attendance", "Attended", "Mtg/Cxld/NoShw", "Attd/Cxld/NoShw", "Cxld/No Show", "Webcast","Replay"];
            return sortOrder.indexOf(valueA) - sortOrder.indexOf(valueB);
        },
    };

    constructor() {
        super();
        this.gridOptions.groupRemoveSingleChildren = true;
        this.gridOptions.getRowStyle = (params) => {
            if (params.node.level == 2 && !params.node.footer && !params.node.rowPinned) {
                return {background: '#ecf0f1'};
            }
        }
        this.gridOptions.isGroupOpenByDefault = params => params.rowNode.key === "Conferences" || this.openGroups.includes(params.key);
        this.gridOptions.pagination = false;
        this.gridOptions.groupDisplayType = "singleColumn";

    }

    ngOnChanges(changes: SimpleChanges): void {
        this.openGroups = [];
        this.gridApi?.forEachNode(node => {
            if (node.group && node.expanded) {
                this.openGroups.push(node.key);
            }
        });

        this.gridOptions.groupIncludeTotalFooter = !this.conferences;
        let yearRange = _.range(+this.year - 4, +this.year + 1);

        let rowData = this.rvcData ? this.rvcData
            .filter(a =>
                ['C', 'K', 'A', 'N', 'I', 'V', ""].includes(a.Category)
                && a.Year !== yearRange[0]
                && (this.conferences ? !!a.Conference && a.SubCategory !== "Cxld/No Show" : true)
                && (this.conferences == false ? a.Conference != "Total" : true)
            ) : [];


        if (this.displayMeetingSeating === "Meetings") {
            this.columnDefs.length = 0;
            this.columnDefs.push(...this.meetingColumnDefs);
        } else {
            this.columnDefs.length = 0;
            this.columnDefs.push(...this.seatsColumnDefs);
        }

        if (this.gridApi) {
            this.gridApi.setColumnDefs(this.columnDefs);
        }

        this.gridColumnApi?.removeRowGroupColumn('ConferenceSubCategoryGroup');
        this.gridColumnApi?.removeRowGroupColumn('SubCategory');


        if (this.conferences) {
            rowData = rowData.map(a => {
                a.YoYSubCategorySeats = a.ConferenceSubCategoryYoYChangeSeats
                a.YoYSubCategoryMeetings = a.ConferenceSubCategoryYoYChangeMeeting
                a.YoYCategorySeats = a.ConferenceYoYChangeSeats
                a.YoYCategoryMeetings = a.ConferenceYoYChangeMeeting
                a.YoYTotalSeats = a.TotalYoYChangeSeatsConference
                a.YoYSubCategoryGroupSeats = a.ConferenceSubCategoryGroupYoYChangeSeats
                a.YoYAttendedTotalSeats = a.ConferenceYoYTotalAttendedSeatCount
                a.YoYSubCategoryGroupMeetings = a.ConferenceSubCategoryGroupYoYChangeMeeting
                return a;
            })
            this.rowData = [...rowData];
            this.gridColumnApi?.removeRowGroupColumn('CategoryName');
            this.gridColumnApi?.addRowGroupColumn('Conference');
        } else {
            rowData = rowData.map(a => {
                a.YoYSubCategorySeats = a.SubCategoryYoYChangeSeats
                a.YoYSubCategoryMeetings = a.SubCategoryYoYChangeMeeting
                a.YoYCategorySeats = a.CategoryYoYChangeSeats
                a.YoYCategoryMeetings = a.CategoryYoYChangeMeeting
                a.YoYTotalSeats = a.TotalYoYChangeSeats
                a.YoYSubCategoryGroupMeetings = a.SubCategoryGroupYoYChangeMeeting
                a.YoYSubCategoryGroupSeats = a.SubCategoryGroupYoYChangeSeats
                a.YoYAttendedTotalSeats = a.TotalYoYChangeSeatsAttended
                return a;
            })
            this.rowData = rowData;
            this.gridColumnApi?.removeRowGroupColumn('Conference');
            this.gridColumnApi?.addRowGroupColumn('CategoryName');
        }
        this.gridColumnApi?.addRowGroupColumn('ConferenceSubCategoryGroup');
        this.gridColumnApi?.addRowGroupColumn('SubCategory');

    }

    private getRowNodeLevels(level: number): RvcRowNodeLevels {
        return {
            isCategoryRow: level == 0,
            isTotalGridRow: level < 0,
            isSubCategoryGroupRow: level == 1
        };
    }

    private yoySeatsPercentChangeAggFunc(params) {

        let levels = this.getRowNodeLevels(params.rowNode.level);

        if (levels.isCategoryRow) {
            let yoy = params.values[0]?.YoYCategorySeats;
            return {
                ConferenceSubCategoryGroup: params.values[0]?.ConferenceSubCategoryGroup,
                YoYSubCategorySeats: params.values[0]?.YoYSubCategorySeats,
                YoYCategorySeats: params.values[0]?.YoYCategorySeats,
                YoYTotalSeats: params.values[0]?.YoYTotalSeats,
                YoYSubCategoryGroupSeats: params.values[0]?.YoYSubCategoryGroupSeats,
                YoYAttendedTotalSeats: params.values[0]?.YoYAttendedTotalSeats,
                toString: () => _.round(yoy) + "%"
            }
        }

        if (levels.isTotalGridRow) {
            let yoy = params.values[0]?.YoYTotalSeats;
            return {
                ConferenceSubCategoryGroup: params.values[0]?.ConferenceSubCategoryGroup,
                YoYSubCategorySeats: params.values[0]?.YoYSubCategorySeats,
                YoYCategorySeats: params.values[0]?.YoYCategorySeats,
                YoYTotalSeats: params.values[0]?.YoYTotalSeats,
                YoYSubCategoryGroupSeats: params.values[0]?.YoYSubCategoryGroupSeats,
                YoYAttendedTotalSeats: params.values[0]?.YoYAttendedTotalSeats,
                toString: () => _.round(yoy) + "%"
            }
        }

        if (levels.isSubCategoryGroupRow) {

                let yoy = params.values[0]?.YoYSubCategoryGroupSeats;
                return {
                    ConferenceSubCategoryGroup: params.values[0]?.ConferenceSubCategoryGroup,
                    YoYSubCategorySeats: params.values[0]?.YoYSubCategorySeats,
                    YoYCategorySeats: params.values[0]?.YoYCategorySeats,
                    YoYTotalSeats: params.values[0]?.YoYTotalSeats,
                    YoYSubCategoryGroupSeats: params.values[0]?.YoYSubCategoryGroupSeats,
                    YoYAttendedTotalSeats: params.values[0]?.YoYAttendedTotalSeats,
                    toString: () => _.round(yoy) + "%"
                }
        }
        let yoy = params.values[0]?.YoYSubCategorySeats;
        return {
            ConferenceSubCategoryGroup: params.values[0]?.ConferenceSubCategoryGroup,
            YoYSubCategorySeats: params.values[0]?.YoYSubCategorySeats,
            YoYCategorySeats: params.values[0]?.YoYCategorySeats,
            YoYTotalSeats: params.values[0]?.YoYTotalSeats,
            YoYSubCategoryGroupSeats: params.values[0]?.YoYSubCategoryGroupSeats,
            YoYAttendedTotalSeats: params.values[0]?.YoYAttendedTotalSeats,
            toString: () => _.round(yoy) + "%"
        }
    }

    private yoyMeetingPercentChangeAggFunc(params) {

        let values = params.values;

        let levels = this.getRowNodeLevels(params.rowNode.level);

        if (levels.isCategoryRow) {
            let yoy = values[0]?.YoYCategoryMeetings;
            return {
                ConferenceSubCategoryGroup: values[0]?.ConferenceSubCategoryGroup,
                YoYSubCategoryMeetings: values[0]?.YoYSubCategoryMeetings,
                YoYCategoryMeetings: values[0]?.YoYCategoryMeetings,
                TotalYoYChangeMeeting: values[0]?.TotalYoYChangeMeeting,
                YoYSubCategoryGroupMeetings: values[0]?.YoYSubCategoryGroupMeetings,
                toString: () => _.round(yoy) + "%"
            }
        }

        if (levels.isTotalGridRow) {
            let yoy = values[0]?.TotalYoYChangeMeeting;
            return {
                ConferenceSubCategoryGroup: values[0]?.ConferenceSubCategoryGroup,
                YoYSubCategoryMeetings: values[0]?.YoYSubCategoryMeetings,
                YoYCategoryMeetings: values[0]?.YoYCategoryMeetings,
                TotalYoYChangeMeeting: values[0]?.TotalYoYChangeMeeting,
                YoYSubCategoryGroupMeetings: values[0]?.YoYSubCategoryGroupMeetings,
                toString: () => _.round(yoy) + "%"
            }
        }

        if (values[0]?.ConferenceSubCategoryGroup == "Attendance") {
            return {
                ConferenceSubCategoryGroup: values[0]?.ConferenceSubCategoryGroup,
                YoYSubCategoryMeetings: values[0]?.YoYSubCategoryMeetings,
                YoYCategoryMeetings: values[0]?.YoYCategoryMeetings,
                TotalYoYChangeMeeting: values[0]?.TotalYoYChangeMeeting,
                YoYSubCategoryGroupMeetings: values[0]?.YoYSubCategoryGroupMeetings,
                toString: () => ""
            }

        }
        if (levels.isSubCategoryGroupRow) {
            let yoy = values[0]?.YoYSubCategoryGroupMeetings;
            return {
                ConferenceSubCategoryGroup: values[0]?.ConferenceSubCategoryGroup,
                YoYSubCategoryMeetings: values[0]?.YoYSubCategoryMeetings,
                YoYCategoryMeetings: values[0]?.YoYCategoryMeetings,
                TotalYoYChangeMeeting: values[0]?.TotalYoYChangeMeeting,
                YoYSubCategoryGroupMeetings: values[0]?.YoYSubCategoryGroupMeetings,
                toString: () => _.round(yoy) + "%"
            }
        }

        let yoy = values[0]?.YoYSubCategoryMeetings;
            return {
                ConferenceSubCategoryGroup: values[0]?.ConferenceSubCategoryGroup,
                YoYSubCategoryMeetings: values[0]?.YoYSubCategoryMeetings,
                YoYCategoryMeetings: values[0]?.YoYCategoryMeetings,
                TotalYoYChangeMeeting: values[0]?.TotalYoYChangeMeeting,
                YoYSubCategoryGroupMeetings: values[0]?.YoYSubCategoryGroupMeetings,
                toString: () => _.round(yoy) + "%"
            }
    }

    private seatCountAggFunc(params) {

        let levels = this.getRowNodeLevels(params.rowNode.level);

        const includedCount = _.sum(params.values.map(v => v.IncludedCount));

        if (levels.isCategoryRow || levels.isSubCategoryGroupRow) {
            return {
                Year: params.values[0]?.Year,
                IncludedCount: includedCount,
                toString: () => includedCount ?? 0
            }
        }
        return {
            Year: params.values[0]?.Year,
            IncludedCount: includedCount,
            toString: () => _.sum(params.values.map(v => +v.toString())) ?? 0
        }
    }

    private meetingCountAggFunc(params) {

        let levels = this.getRowNodeLevels(params.rowNode.level);

        const includedCount = _.sum(params.values
            .filter(v => v.ConferenceSubCategoryGroup !== "Attendance")
            .map(v => v.IncludedCount));

        if (params.values[0]?.ConferenceSubCategoryGroup == "Attendance" && !levels.isCategoryRow) {
            return {
                Year: params.values[0]?.Year,
                ConferenceSubCategoryGroup: params.values[0]?.ConferenceSubCategoryGroup,
                IncludedCount: includedCount,
                toString: () => ""
            }
        }
        if (levels.isCategoryRow || levels.isSubCategoryGroupRow) {
            return {
                Year: params.values[0]?.Year,
                IncludedCount: includedCount,
                toString: () => includedCount ?? 0
            }
        }
        return {
            Year: params.values[0]?.Year,
            IncludedCount: includedCount,
            toString: () => _.sum(params.values.map(v => +v.toString())) ?? 0
        }
    }
}

export interface RvcRowNodeLevels {
    isCategoryRow: boolean;
    isTotalGridRow: boolean;
    isSubCategoryGroupRow: boolean;
}
