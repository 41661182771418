import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {BaseHttpService, FileAcceptType} from "./base-http.service";
import {Activity} from "../../../Models/Activity";
import * as moment from "moment";
import {ActivityStatus} from "../../../Models/ActivityStatus";
import {ActivityStockCallCategory} from "../../../Models/ActivityStockCallCategory";
import {ActivitySubCategory} from "../../../Models/ActivitySubCategory";
import {MeetingType} from "../../../Models/MeetingType";
import {Query, QueryGroup} from "../../../Services/QueryService";
import {QueryTranslator} from "../../../Helpers/QueryTranslator";
import {AccountDetailActivitySummary} from "../../../Models/AccountDetailActivitySummary";
import {Contact} from "../../../Models/Contact";
import {RVCSummary} from "../../../Models/RVCSummary";
import {TrendingTicker} from "../../../Models/TrendingTicker";
import {ActivitySummary} from "../../../Models/ActivitySummary";
import {ActivityWeekSum} from "../../../Models/ActivityWeekSum";
import {RegionAccountCategorySummary} from "../../../Models/RegionAccountCategorySummary";
import {ActivityCategory} from "../../../Models/ActivityCategory";

@Injectable({
    providedIn: 'root',
})
export class ActivityService {

    constructor(private baseHttp: BaseHttpService) { }

    getActivityStatuses(): Observable<ActivityStatus[]> {
        return this.baseHttp.getCacheOrData(`/activitystatus`, 5 * 60 * 1000);
    }
    
    getActivitySubCategories(): Observable<ActivitySubCategory[]> {
        return this.baseHttp.getCacheOrData(`/activitysubcategories`, 5 * 60 * 1000);
    }
    
    getActivityStockCallCategories(): Observable<ActivityStockCallCategory[]> {
        return this.baseHttp.getCacheOrData(`/activitystockcallcategory`, 5 * 60 * 1000);
    }
    
    getActivityMeetingTypes(): Observable<MeetingType[]> {
        return this.baseHttp.getCacheOrData(`/activitymeetingtype`, 5 * 60 * 1000);
    }
    
    searchActivities(query: QueryGroup[]): Observable<Activity[]> {
        const params = {
            QueryGroups: query
        };
        return this.baseHttp.postData(`/activity/query`, params);
    }

    getActivitiesByEventId(eventId: number): Observable<Activity[]> {
        return this.baseHttp.getData(`/activity/eventid/${eventId}`);
    }

    getActivitiesByEventMeetingId(eventMeetingId: number): Observable<Activity[]> {
        return this.baseHttp.getData(`/activity/eventmeetingid/${eventMeetingId}`);
    }

    exportActivities(query: any): Observable<any> {
        const params = {
            QueryGroups: query
        };
        return this.baseHttp.getFileFromPost(`/activity/query/export/filename.csv`, 
            `ContactActivities-${moment().format("YYYY-MM-DDTHH.mm.ss")}.csv`, 
            FileAcceptType.Csv, params);
    }
    
    getActivityResourcePoints(year: number): Observable<any> {
        return this.baseHttp.getFileWithApiFileName(`/activityresourcepoints?year=${year}`, FileAcceptType.Excel);
    }
    
    getActivityResourcePointsNew(year: number): Observable<any> {
        return this.baseHttp.getFileWithApiFileName(`/activityresourcepointsnew?year=${year}`, FileAcceptType.Excel);
    }

    getMeetingMeetingTypes(): Observable<MeetingMeetingType[]> {
        return of([{ Id: "M-S", Name: "M-S Account / Strategic" }, { Id:"M-T", Name: "M-T Trader", Description: "Trader" }]);
    }
    
    getMeetingContexts(): Observable<MeetingContext[]> {
        return of([{Id: "Business", Name: "Business"}, {Id: "Social", Name: "Social"}]);
    }
    
    getAccountActivities(showGrid: boolean, searchTerm: string, start: moment.Moment, end: moment.Moment, callerTeams: string): Observable<any[]> {
        let config = {
            params: {
                grid: showGrid.toString(),
                searchTerms: searchTerm,
                startDate: start.format("YYYY-MM-DD"),
                endDate: end.format("YYYY-MM-DD"),
                teamIds: callerTeams,
            }
        };
        return this.baseHttp.getDataWithConfig(`/accountactivities`, config);
    }

    public getAccountActivityExport(showGrid: boolean, searchTerm: string, start: moment.Moment, end: moment.Moment, callerTeams: string): Observable<any> {
        const params =  {
            grid: showGrid.toString(),
            searchTerms: searchTerm,
            startDate: start.format("YYYY-MM-DD"),
            endDate: end.format("YYYY-MM-DD"),
            teamIds: callerTeams,
        };

        return this.baseHttp.getFileWithApiFileName(`/account/activities/export`, FileAcceptType.Excel, params);
    }

    public getRvcByAccountIds(
        accountIds: number[],
        startDate: string,
        endDate: string,
        teamIds: number[],
        specificMonthRange: number[]
    ): Observable<RVCSummary[]> {

        let groups = [];
        QueryTranslator.AddQueryGroupValues(groups, "Account", "Instnum", "contains", accountIds);
        QueryTranslator.AddQueryGroupValues(groups, "Activity", "Call Date", "between", [startDate, endDate]);
        QueryTranslator.AddQueryGroupValues(groups, "Activity", "Category", "contains", ["C", "K", "A", "N", "I", "V", "P", "X", "D"]);
        
        if (teamIds && teamIds.length > 0) {
            QueryTranslator.AddQueryGroupValues(groups, "Activity", "Baird Caller Team", "contains", teamIds);
        }

        if (specificMonthRange.length > 0 && specificMonthRange.length < 12) {
            QueryTranslator.AddQueryGroupValues(groups, "Activity", "Call Date Months", "contains", specificMonthRange);
        }
        const params = {
            QueryGroups: groups
        };
        return this.baseHttp.postData(`/pivottableactivities`, params);
    }

    public getAccountRvCExport(accountIds: number[],
                               startDate: string,
                               endDate: string,
                               exportName: string,
                               teamIds: number[],
                               specificDateRange?: number[]): Observable<void> {

        let groups = [];
        QueryTranslator.AddQueryGroupValues(groups, "Account", "Instnum", "contains", accountIds);
        QueryTranslator.AddQueryGroupValues(groups, "Activity", "Call Date", "between", [startDate, endDate]);
        QueryTranslator.AddQueryGroupValues(groups, "Activity", "Category", "contains", ["C", "K", "A", "N", "I", "V", "P", "X", "D"]);

        if (teamIds && teamIds.length > 0) {
            QueryTranslator.AddQueryGroupValues(groups, "Activity", "Baird Caller Team", "contains", teamIds);
        }

        if (specificDateRange.length > 0 && specificDateRange.length < 12) {
            QueryTranslator.AddQueryGroupValues(groups, "Activity", "Call Date Months", "contains", specificDateRange);
        }
        const params = {
            QueryGroups: groups
        };
        
        return this.baseHttp.getFileFromPost(
            "/account/activities/rvc/export",
            exportName,
            FileAcceptType.Excel,
            params
        );
    }

    public getActivityCategoriesByQueryGroups(query: QueryGroup[]): Observable<string[]> {
        const params = {
            QueryGroups: query
        };
        return this.baseHttp.postData(`/activitycategories/query`, params);
    }

    public getActivityCategories(): Observable<ActivityCategory[]> {
        return this.baseHttp.getCacheOrData(`/activitycategories`, 5 * 60 * 1000);
    }
    
    public getAccountDetailActivitySummaries(accountId: number, query: AccountDetailActivityQuery): Observable<AccountDetailActivitySummary[]> {
        return this.baseHttp.getDataWithConfig(`/account/id/${accountId}/activities/`, { params: query as any});
    }

    public getActivityAttendees(accountId: number, analyst: string, category: string, date: string | moment.Moment, description: string, type: string, tickers: string): Observable<Contact[]> {
        const params = {
            analyst: analyst,
            category: category,
            date: moment(date).format("YYYY-MM-DD"),
            description: description,
            tickers: tickers ?? "",
            type: type ?? ""
        };
        return this.baseHttp.getDataWithConfig(`/account/${accountId}/activity/attendees`, { params })
    }

    public getAccountDetailSummaryActivitiesExport(accountId: number, queryParameters: AccountDetailActivityQuery): Observable<any> {
        return this.baseHttp.getFileFromGet(
            `/account/id/${accountId}/activities/export`,
            `AccountDetailActivities-${moment().format("YYYY-MM-DDTHH.mm.ss")}.xlsx`,
            FileAcceptType.Excel, 
            queryParameters
        );
    }
    
    public getAccountRvCActivitySummaryExport(queryParameters: AccountDetailActivityQuery, exportName: string): Observable<void> {
        return this.baseHttp.getFileFromGet(
            "/account/activities/rvc/export", 
            exportName,
            FileAcceptType.Excel, 
            queryParameters
        );
    }

    getTrendingTickers(categories: string[], daysback: number) : Observable<TrendingTicker[]> {
        const params = {
            cats: categories,
            daysback: daysback.toString()
        };

        return this.baseHttp.getDataWithConfig(`/trendingtickers`, { params: params });
    }

    getTickerSummary(ticker: string, categories: string[], daysback: number) : Observable<ActivitySummary[]> {
        const params = {
            cats: categories,
            lookback: daysback.toString()
        };

        return this.baseHttp.getDataWithConfig<ActivitySummary[]>(`/ticker/${ticker}/activitysummary`, { params: params });
    }

    getActivityById(activityId: number): Observable<Activity> {
        return this.baseHttp.getData(`/activity/id/${activityId}`);
    }

    createActivity(activity: Activity): Observable<Activity> {
        return this.baseHttp.postData(`/activity/id`, activity);
    }

    updateActivity(activity: Activity) {
        return this.baseHttp.postData(`/activity/id/${activity.Id}`, activity);
    }
    
    deleteActivity(activityId: number): Observable<any> {
        return this.baseHttp.deleteData(`/activity/id/${activityId}`);
    }

    getTeamActivities(teamId: number, startDate: moment.Moment, endDate: moment.Moment): Observable<ActivityWeekSum[]> {
        return this.baseHttp.getData(`/team/id/${teamId}/activities?startDate=${startDate.format("YYYY-MM-DD")}&endDate=${endDate.format("YYYY-MM-DD")}`);
    }
    
    getRegionActivitySummaries(query: Query, teamId: number): Observable<RegionAccountCategorySummary[]> {
        const params = {
            teamId: teamId?.toString(),
        }
        return this.baseHttp.postDataWithConfig('/activity/regionsummary/', query, { params });
    }

    getActivityEmailPreview(
        activityId: number,
        activityEmailSpecificationDto: ActivityEmailSpecificationDto,
    ): Observable<ActivityEmail> {
        return this.baseHttp.postData(
            `/activity/id/${activityId}/email/preview`,
            activityEmailSpecificationDto,
        );
    }

    sendActivityEmail(
        activityId: number,
        activityEmailSpecificationDto: ActivityEmailSpecificationDto,
    ): Observable<any> {
        return this.baseHttp.postData(`/activity/id/${activityId}/email`, activityEmailSpecificationDto);
    }
}

export interface MeetingMeetingType {
    Id: string;
    Name: string;
}

export interface MeetingContext {
    Id: string;
    Name: string;
}

export class AccountDetailActivityQuery {
    public accountIds: number[] = [];
    public analystTimeActivityGroupFilter: string = "";
    public analystTimeTeamIds: string = "";
    public corpAccessActivityGroupFilter: string = "";
    public corpAccessTeamIds: string = "";
    public categories: string[] = [];
    public startDate: string | moment.Moment;
    public endDate: string | moment.Moment;
    public yoyRequestYears: number[] = [];
    public yoyShowYears: number[] = [];
    public months: number[] = [];
}

export interface ActivityEmailSpecificationDto {
    ToUserIds?: string[];
    CcUserIds?: string[];
    ClientEntertainmentActivityComment: string;
    ExcelModelTickers: string[];
    ContactIds: number[];
}

export interface ActivityEmail {
    Body: string;
    CcUserIds: string[];
    ToUserIds: string[];
    From: string;
    Subject: string;
}